import React from "react"
import classNames from "classnames"
import "./infobox.scss"
import { Title} from "../../Layout/Title/title";
import RichText from "../../Modules/RichText/richText"

export const Infobox = props => {
  const classnames = classNames("m-infobox", {
    // layourt
    "m-infobox--main": props.main,

  });
  return (

      <div className={classnames} data-qa={props.dataQa}>
          <Title infobox regular content={props.title} />
          <RichText content={props.content}/>
      </div>
  )
}
