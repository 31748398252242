import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import RichText from "../components/Modules/RichText/richText"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import { Infobox} from "../components/Modules/Infobox/infobox";
import { Title} from "../components/Layout/Title/title";
import { StepBoxSingle, StepBoxGrid, StepBoxColumns } from "../components/Modules/StepBox/StepBox";
//import SEO from "../components/Modules/SEO/seo";
//import { checkUtmParams } from "../services/sem/checkUtmParams";
import {Helmet} from "react-helmet";

class InstructionsPageTemplate extends Component {
/*    componentDidMount() {
        checkUtmParams();
    }*/

    render() {
        const page = this.props.data.contentfulInstructionsPage
        const site = this.props.data.site
        return (
            <Layout headerTitle={page.title} hideMenuButton={false}>
                <Helmet>
                    <title>{page.title}</title>
                    <meta name="description" content={page.metadescription} />
                </Helmet>
                <div>
                    <Wrapper isSteps medium classNames={`m-wrapper--instructionsPage`}>
                        <Infobox main title={page.subtitle} content={page.description.json} />
                        <StepBoxSingle title={page.stepOne} content={page.stepOneNote.json}/>
                        <StepBoxGrid title={page.stepTwo} content={page.stepOneNote.json} blocks={page.stepBlocks} />
                        <StepBoxColumns title={page.stepThree} content={page.stepThreeText.json} note={page.stepThreeNote.json} agencyButton={page.agencyNearYou} linkButtonAgency={page.linkButtonAgency} />
                    </Wrapper>
                </div>
            </Layout>
        )
    }
}

export default InstructionsPageTemplate

export const pageQuery = graphql`
    query($slug: String) {
        contentfulInstructionsPage(slug: { eq: $slug }) {
            slug
            title
            id
            metadescription
            stepOne
            stepThree
            stepTwo
            subtitle
            description {
              id
              json
            }
      
            stepOneNote {
               json    
           }
            stepBlocks {
              title
              button
              content {
                json
              }
              linkToPage {
                slug
              }
            }
            stepThreeText {
              json
            }
            stepThreeNote {
              json
            }
          
            agencyNearYou
            linkButtonAgency
        }
        site {
            siteMetadata {
                company_name
                web_full_name
                web_mail
            }
        }
    }
`
