import React from "react"
import "./StepBox.scss"
import { Title} from "../../Layout/Title/title";
import RichText from "../../Modules/RichText/richText"
import { Button} from "../../Layout/Buttons/buttons";

export const StepBoxSingle = ( { title, content } ) => {

  return (

      <div className="m-stepbox m-stepbox--single">
            <Title extralarge steps content={title}/>
            <RichText content={content}/>
      </div>
  )
}

export const StepBoxGrid = ( { title, content, blocks } ) => {

    return (

        <div className="m-stepbox">
            <Title extralarge steps content={title}/>
            <div className="m-stepbox--grid">
                {blocks.map((card, index) => {
                        return (
                            <div key={index} className="m-stepbox--grid--item">
                                <Title isCard regular content={card.title}/>
                                <RichText content={card.content.json}/>
                                {card.button && <div className="m-stepbox--grid--link">
                                     <Button primaryAlt label={card.button} link={`../${card.linkToPage.slug}`}  />
                                </div>}
                            </div>
                        )
                    }
                )}
            </div>
        </div>
    )
}

export const StepBoxColumns = ( { title, content, note, agencyButton, linkButtonAgency } ) => {

    return (

        <div className="m-stepbox m-stepbox--columns">
            <div className="m-stepbox--head">
                <Title extralarge steps content={title}/>
            </div>
            <div className="m-stepbox--body">
                <div className="m-stepbox--columns--wrapper">
                    <div className="m-stepbox--column">
                        <RichText content={content}/>
                        <div className="m-stepbox--grid--link">
                            {agencyButton && <Button withMarker secondary setTarget="_blank" label={`Locate a Facility or Agency Near You`} link={linkButtonAgency} /> }
                        </div>
                    </div>
                    <div className="m-stepbox--column m-stepbox--note">
                        <div className="m-stepbox--note--label">Note:</div>
                        <RichText content={note}/>
                    </div>
                </div>
            </div>



        </div>
    )
}
